import { JsonPipe } from '@angular/common';
/* eslint-disable @typescript-eslint/no-empty-function */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

import { TextInputComponent } from '../../core/text-input/text-input.component';
import { BaseFormFieldComponent } from '../base-form-field.component';
import { FormFieldWrapperComponent } from '../form-field-wrapper.component';

@Component({
  selector: 'do-text-field',
  template: `
    <do-form-field-wrapper
      [label]="label"
      [shouldShowError]="shouldShowError()"
      [errors]="errors()"
      [tooltip]="tooltip"
      [showHint]="showHint"
    >
      <do-text-input
        [controlType]="controlType"
        [placeholder]="placeholder"
        [hasError]="shouldShowError()"
        [formControl]="formControl"
        [longText]="longText"
        [readOnly]="readOnly"
        [forceUppercase]="forceUppercase"
        (blur)="onBlur()"
      >
      </do-text-input>
    </do-form-field-wrapper>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextFieldComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormFieldWrapperComponent,
    ReactiveFormsModule,
    TextInputComponent,
    JsonPipe,
  ],
})
export class TextFieldComponent extends BaseFormFieldComponent {
  @Input()
  controlType: 'text' | 'password' | 'tel' | 'email' = 'text';

  @Input()
  placeholder = '';

  @Input()
  longText = false;

  @Input()
  forceUppercase = false;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  blur = new EventEmitter();

  onBlur() {
    this.blur.emit();
  }
}
