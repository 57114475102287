/* eslint-disable @typescript-eslint/no-empty-function */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'do-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true,
    },
  ],
})
export class TextInputComponent implements ControlValueAccessor {
  @Input()
  controlType = 'text';

  @Input()
  placeholder = '';

  @Input()
  readOnly = false;

  @Input()
  longText = false;

  @Input()
  disabled = false;

  value = '';

  @Input()
  hasError = false;

  @Input()
  forceUppercase = false;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  blur = new EventEmitter();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  propagateChange = (value: string) => {};
  onTouched = () => {};
  validateFn: any = () => {};

  validate(control: FormControl) {
    const res = this.validateFn(control);
    if (res) this.hasError = true;
    return res;
  }

  constructor(private cd: ChangeDetectorRef) {}

  onChange(value: string) {
    if (this.forceUppercase) {
      this.value = value.toUpperCase();
    } else {
      this.value = value;
    }
    this.propagateChange(this.value);
    this.onTouched();
  }

  writeValue(obj: string) {
    this.value = obj;
    this.cd.detectChanges();
  }

  registerOnChange(fn: (value: string) => void) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this.cd.detectChanges();
  }

  onBlur() {
    this.blur.emit();
  }
}
