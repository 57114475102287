import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

import { ValidationMessageProvider } from './validation-message-provider';

@Pipe({ name: 'formsError', standalone: true })
export class FormsErrorPipe implements PipeTransform {
  constructor(private validationMessageProvider: ValidationMessageProvider) {}

  transform(value: ValidationErrors | null): string[] {
    const errors: string[] = [];
    if (value) {
      Object.keys(value).forEach((errKey) => {
        const err = value[errKey];
        errors.push(this.validationMessageProvider.getMessage(errKey, err));
      });
    }
    return errors;
  }
}
