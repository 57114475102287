import { inject, Injectable } from '@angular/core';
import { marker } from '@do/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ValidationMessageProvider {
  private translateService = inject(TranslateService);

  public defaultValidationMessages: {
    [key: string]: string;
  } = {
    required: marker('mandatory field'),
    min: marker('lower allowed value is _min'),
    max: marker('higher allowed value is _max'),
    pattern: marker('invalid format'),
    email: marker('invalid email format'),
    maxLength: marker('character limit is _maxLength'),
    minLength: marker('the minimum number of characters is _minLength'),
    equalTo: marker('_name must be equal to _comparandName'),
    lessThan: marker('_name must be less than _comparandName'),
    moreThan: marker('_name must be greater than _comparandName'),
    notEqualTo: marker('_name must be different from _comparandName'),
    dateLessThan: marker('date should be less than _comparandValue'),
    dateLessThanOrEqual: marker(
      'date should be less than or equal to _comparandValue'
    ),
    dateGreaterThan: marker('date should be greater than _comparandValue'),
    dateGreaterThanOrEqual: marker(
      'date should be greater than or equal to _comparandValue'
    ),
  };

  getMessage(key: string, errorInfo: any): string {
    let msg = this.translateService.instant(
      this.defaultValidationMessages[key] || key
    );

    const parameters: { [key: string]: string } = {};
    Object.keys(errorInfo).forEach((ph) => {
      if (errorInfo[ph] && typeof errorInfo[ph] === 'string') {
        parameters[ph] = errorInfo[ph];
      }
    });
    if (msg.indexOf('_') > -1 && errorInfo) {
      Object.keys(errorInfo).forEach((ph) => {
        msg = msg.replace('_' + ph, errorInfo[ph]);
      });
    }

    if (!msg) {
      return '';
    } else {
      return msg;
    }
  }
}
