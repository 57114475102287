<input
  *ngIf="!longText"
  #input
  [type]="controlType"
  class="form-control"
  [class.is-invalid]="hasError"
  (blur)="onBlur()"
  [disabled]="disabled"
  [placeholder]="placeholder"
  [ngModel]="value"
  tabindex="0"
  [readOnly]="readOnly"
  (ngModelChange)="onChange($event)"
  ngbAutofocus
/>
<textarea
  *ngIf="longText"
  #input
  tabindex="0"
  class="form-control"
  [class.is-invalid]="hasError"
  (blur)="onBlur()"
  [disabled]="disabled"
  [readOnly]="readOnly"
  [placeholder]="placeholder"
  [ngModel]="value"
  (ngModelChange)="onChange($event)"
></textarea>
