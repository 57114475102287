import {
  catchError,
  concat,
  distinctUntilChanged,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  tap,
} from 'rxjs';

import { CommonModule } from '@angular/common';
/* eslint-disable @typescript-eslint/no-empty-function */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ApiClientInterface } from '@do/app-common';
import { BaseDto } from '@do/common-dto';
import { FilterOperator, FilterType } from '@do/common-interfaces';
import { DropdownPosition, NgSelectModule } from '@ng-select/ng-select';

export interface AutocompleteSelectConfig {
  searchFields: string[];
  orderBy: { [field: string]: 'asc' | 'desc' };
  apiClient: ApiClientInterface<BaseDto>;
  bindLabel: string;
}

@Component({
  selector: 'do-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgSelectModule, FormsModule, CommonModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent implements OnChanges, ControlValueAccessor {
  @Input()
  appendTo?: string = undefined;

  @Input()
  clearable = true;

  @Input()
  searchable = false;

  // @Input()
  // items: any[] = [];
  private _items: any[] = [];
  get items() {
    return this._items;
  }
  @Input()
  set items(values: any[]) {
    this._items = values;
    if (!this.autocompleteConfig) {
      this.items$ = of(values);
    }
  }

  @Input()
  bindValue?: string;

  @Input()
  bindLabel?: string;

  @Input()
  placeholder = '';

  @Output()
  changed = new EventEmitter();

  isDisabled = false;

  @Input()
  hasError = false;

  @Input()
  multiple = false;

  @Input()
  autocompleteConfig?: AutocompleteSelectConfig;

  @Input()
  minTermLength = 0;

  @Input()
  canAdd? = false;

  @Input()
  canView? = false;

  @Input()
  entityName?: string;

  @Input()
  readOnly = false;

  @Input()
  dropdownPosition: DropdownPosition = 'auto';

  value: any;

  items$?: Observable<BaseDto[]>;
  dataLoading = false;
  textInput$: Subject<string> = new Subject<string>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  propagateChange = (value: any) => {};
  validateFn: any = () => {};
  onTouched = () => {};

  constructor(
    private cd: ChangeDetectorRef // private routerFacade: RouterFacade
  ) {}

  writeValue(obj: any): void {
    this.value = obj;
    this.cd.markForCheck();
  }

  registerOnChange(fn: (value: any) => void) {
    this.propagateChange = fn;
  }

  setDisabledState(isDisabled: any): void {
    this.isDisabled = isDisabled;
    this.cd.markForCheck();
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  validate(control: FormControl) {
    const res = this.validateFn(control);
    if (res) this.hasError = true;
    return res;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    if (this.autocompleteConfig) {
      const apiClient = this.autocompleteConfig.apiClient;
      const searchFields = this.autocompleteConfig.searchFields;
      const orderBy = this.autocompleteConfig.orderBy;

      this.items$ = concat(
        of([]), // default items
        this.textInput$.pipe(
          distinctUntilChanged(),
          tap(() => {
            this.dataLoading = true;
          }),
          switchMap((term) =>
            apiClient
              .getPaged(
                10,
                0,
                orderBy,
                searchFields.map((field) => ({
                  field,
                  operator: FilterOperator.contains,
                  value: [term],
                  type: FilterType.text,
                }))
              )
              .pipe(
                map((res) => res.items),
                catchError(() => of([])), // empty list on error
                tap(() => (this.dataLoading = false))
              )
          )
        )
      );
    } else {
      this.items$ = of(this.items);
    }
  }

  onModelChange($event: any) {
    this.changed.emit($event);
    this.propagateChange($event);
    this.onTouched();
  }

  async add() {
    if (this.entityName) {
      // return this.routerFacade.addNewEntity(this.entityName);
    }
  }

  async view() {
    if (this.entityName) {
      // this.routerFacade.viewEntityDetail(this.entityName, this.value);
    }
    // if (w) {
    //   w.onunload = (e) => alert('new window closed');
    // }
  }
}
