<ng-select
  class="do-select"
  (ngModelChange)="onModelChange($event)"
  [clearable]="clearable"
  [class.is-invalid]="hasError"
  [disabled]="isDisabled"
  [items]="items$ | async"
  [bindLabel]="$any(bindLabel)"
  [bindValue]="$any(bindValue)"
  [(ngModel)]="value"
  [appendTo]="$any(appendTo)"
  [searchable]="searchable"
  [placeholder]="placeholder"
  [multiple]="multiple"
  [typeahead]="textInput$"
  [loading]="dataLoading"
  [minTermLength]="minTermLength"
  [readonly]="readOnly"
>
  <ng-template ng-option-tmp let-item="item">
    <div
      [title]="bindLabel ? item[bindLabel] : item"
      [innerHTML]="bindLabel ? item[bindLabel] : item"
    ></div>
  </ng-template>

  <ng-template ng-label-tmp let-item="item">
    <div
      class="d-flex align-items-center"
      [title]="bindLabel ? item[bindLabel] : item"
      [innerHTML]="bindLabel ? item[bindLabel] : item"
    ></div>
  </ng-template>
</ng-select>
<i
  *ngIf="canView && value"
  class="ms-1 cursor-pointer text-primary feather icon-corner-up-right"
  (click)="view()"
></i>
<i
  *ngIf="canAdd"
  class="ms-1 cursor-pointer text-primary feather icon-file-plus"
  (click)="add()"
></i>
